.ss-sidebar {
  
    position: fixed;
    top: 0;
    bottom: 0;
    left: -$ss-sidebar-width;
    z-index: 5;
    width: $ss-sidebar-width;
    font-weight: 400;
    color: #fff;
    background-position: center center;
    background-size: cover;
    transition: left 0.1s ease-in;
  
    + .ss-content {
      transition: margin 0.1s ease-in;
    }
  
    &::after,
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
    }
  
    &::before {
      background: #000;
      opacity: 0.33;
    }
  
    &::after {
      z-index: 3;
      // sublime light
      background: #00b398; /* fallback for old browsers */
      background: linear-gradient(to bottom, #00b398, #007864);
      opacity: 1;
    }
  
    &[data-image]:after {
      opacity: 0.77;
    }
  
    &__content {
      position: relative;
      z-index: 4;
      height: calc(100vh - 20px);
      padding-bottom: 30px;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
  
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  
    &__background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
    }
  
    &__nav-item-collapse {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  
    &__nav-item-icon {

      width: 2rem;
      height: 2rem;
      padding-right: 0.5rem;
    }
  
    &--open {
      left: 0;
      transition: left 0.2s ease-in;
  
      + .ss-content {
        margin: 0 0 0 $ss-sidebar-width;
        transition: margin 0.2s ease-in;
      }
    }
  
    .nav {
      margin-top: 20px;
  
      .nav-item {
        margin: 5px 10px;
        color: theme-color(light);
        border-radius: 4px;
        opacity: 0.86;

      }
  


      .nav-link {

        color: #f8f9fa;
        width: 100%;

        &:hover {
          background: rgba(#f8f9fa, 0.15);
          
        }
      }
    }
  }
  