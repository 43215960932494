.ss-header {
  margin-bottom: $spacer * .5;
  border-bottom: $border-width solid $border-color;
  
  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }
}
  