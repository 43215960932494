.nav-item {
  .nav-link {
    display: flex;
    align-items: center;
    font-size: large;
  
    &.active {
      color: #007864;
      opacity: 1;
    }
  }
}
  
.sidebar .nav a {
   transition: all 150ms ease-in;
}
  
.card-header {
  text-transform: uppercase;
}
  
.badge:empty {
  display: inline-block;
}

.badge {
  background: #00b398;
  font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
}

.navbar {
  padding: 1rem 0rem;
}
  
.navbar-expand {
  padding: 0.5rem 1rem;
}

.modal-dialog {
  max-width: 960px;
}

