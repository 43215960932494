// page
.ss-page {
  min-height: 100vh;
  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: center;
    align-self: center;
    padding: 0.1rem;
  }
  
  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  
  .row {
    // stylelint-disable-next-line      
    .col,
    > [class^='col-'] {
      margin-bottom: 1.5rem;
    }
  }

}
  
.ss-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
