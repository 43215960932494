// source: https://github.com/daneden/animate.css

@keyframes swing {
    20% {
      transform: rotate3d(0, 0, 1, 15deg);
    }
  
    40% {
      transform: rotate3d(0, 0, 1, -10deg);
    }
  
    60% {
      transform: rotate3d(0, 0, 1, 5deg);
    }
  
    80% {
      transform: rotate3d(0, 0, 1, -5deg);
    }
  
    to {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }
  
  .swing {
    transform-origin: top center;
    animation-name: swing;
  }
  
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  